<template>
    <default-app>
        <template v-slot:title>Konfigurationstool</template>
        <template v-slot:nav>
            <ul>
                <li>
                    <router-link to="/"><i class="fal fa-tachometer"></i>Dashboard</router-link>
                </li>
                <li>
                    <router-link to="/roles"><i class="fal fa-ruler"></i>Rollen</router-link>
                </li>
                <li>
                    <router-link to="/tasks"><i class="fal fa-tasks"></i>Aufgaben</router-link>
                </li>
                <li :class="{ active: open == 'crefo' }">
                    <a @click.prevent="toggle('crefo')" href="#" nav-group-header><i class="fal fa-credit-card-blank"></i>CreditReform</a>
                    <ul>
                        <li><router-link :to="{ name: 'crefo_lists', params: { goto: 'crefo_keylistentries' } }">Alle Listen</router-link></li>
                        <li><router-link :to="{ name: 'crefo_filtered' }">Listen verwalten</router-link></li>
                        <li><router-link :to="{ name: 'crefo_checklists' }">Prüfungslisten</router-link></li>
                        <li><router-link :to="{ name: 'crefo_configs' }">Prüfungen konfigurieren</router-link></li>
                        <li><router-link :to="{ name: 'crefo_assignments' }">Prüfungen zuweisen</router-link></li>
                    </ul>
                </li>
                <li :class="{ active: open == 'ktvm' }">
                    <a @click.prevent="toggle('ktvm')" href="#" nav-group-header><i class="fal fa-home"></i>KTV-M</a>
                    <ul>
                        <li><router-link :to="{ name: 'ktvm_unternehmen_blacklist' }">Blacklist Unternehmen</router-link></li>
                        <li><router-link :to="{ name: 'ktvm_mapping_ms' }">URL-Zuordnung Microsite</router-link></li>
                    </ul>
                </li>
                <li :class="{ active: open == 'bsv' }">
                    <a @click.prevent="toggle('bsv')" href="#" nav-group-header><i class="fal fa-piggy-bank"></i>BSV</a>
                    <ul>
                        <li><router-link :to="{ name: 'bsv_antragsdaten_pruefen' }">Antragsdaten prüfen</router-link></li>
                    </ul>
                </li>
                <li>
                    <router-link to="/statistics"><i class="fal fa-chart-bar"></i>Statistiken</router-link>
                </li>
                <li :class="{ active: open == 'docs' }">
                    <a @click.prevent="toggle('docs')" href="#" nav-group-header><i class="fal fa-file-signature"></i>Dokumente &amp; E-Mails</a>
                    <ul>
                        <!-- <li><router-link :to="{ name: 'docs_emailtemplates' }">Vorlagen</router-link></li> -->
                        <li><router-link :to="{ name: 'docs_pdfforms' }">PDF Formulare</router-link></li>
                        <li><router-link :to="{ name: 'docs_process_list' }">Zuordnen</router-link></li>
                        <li><router-link :to="{ name: 'docs_assignments' }">Zuordnungen anzeigen</router-link></li>
                    </ul>
                </li>
            </ul>
            <div class="toolbar">
                <button class="btn flat"><i class="fal fa-cogs"></i></button>
            </div>
        </template>
    </default-app>
</template>

<script>
import App from "@altimate-net/vue-components/src/App";

export default {
    data() {
        return {
            open: ""
        };
    },
    methods: {
        toggle(group) {
            if (this.open === group) {
                this.open = "";
            } else {
                this.open = group;
            }
        }
    },
    components: {
        "default-app": App
    }
};
</script>

<style lang="scss">
// @TODO: Overwrite scss variables for R+V style here
@import "@altimate-net/vue-components/src/assets/scss/v1.0.0.scss";

div.spacer {
    flex-grow: 1;
}

table.rotated {
    th {
        z-index: 10;

        div {
            z-index: 100;
        }
    }
}
</style>
