import Vue from "vue";
import Router from "vue-router";
import Dashboard from "./views/Dashboard.vue";
import VueCookie from "vue-cookie";

import StatusRoutes from "@altimate-net/vue-components/src/route/status";
import Wrapper from "@altimate-net/vue-components/src/components/Wrapper";

Vue.use(Router);

var allRoutes = [];
allRoutes = allRoutes.concat(StatusRoutes, [
    {
        path: "/",
        name: "dashboard",
        component: Dashboard
    },
    {
        path: "/ausgeloggt",
        name: "logged_out",
        meta: { public: true, layout: "public" },
        component: () => import(/* webpackChunkName: "logged_out" */ "./views/auth/Loggedout.vue")
    },
    {
        path: "/parser",
        name: "parser",
        component: () => import(/* webpackChunkName: "parser" */ "./views/Parser.vue")
    },
    {
        path: "/emaillists",
        name: "emaillists",
        component: () => import(/* webpackChunkName: "emaillists" */ "./views/EmailList.vue")
    },
    {
        path: "/roles",
        name: "roles",
        component: () => import(/* webpackChunkName: "roles" */ "./views/Roles.vue")
    },
    {
        path: "/tasks",
        name: "tasks",
        component: () => import(/* webpackChunkName: "tasks" */ "./views/Tasks.vue")
    },
    {
        path: "/crefo",
        name: "",
        component: Wrapper,
        children: [
            {
                path: "lists/:goto",
                name: "crefo_lists",
                component: () => import(/* webpackChunkName: "crefo_lists" */ "./views/crefo/Lists.vue")
            },
            {
                path: "entries/:id",
                name: "crefo_keylistentries",
                component: () => import(/* webpackChunkName: "crefo_keylistentries" */ "./views/crefo/KeylistEntries.vue")
            },
            {
                path: "filtered",
                name: "crefo_filtered",
                component: () => import(/* webpackChunkName: "crefo_filteredlists" */ "./views/crefo/FilteredLists.vue")
            },
            {
                path: "filtered/edit/:id",
                name: "crefo_filtered_edit",
                component: () => import(/* webpackChunkName: "crefo_filterededit" */ "./views/crefo/FilteredEdit.vue")
            },
            {
                path: "checklists",
                name: "crefo_checklists",
                component: () => import(/* webpackChunkName: "crefo_checklists" */ "./views/crefo/Checklists.vue")
            },
            {
                path: "checklist/create_freetext",
                name: "crefo_checklist_createfreetext",
                component: () => import(/* webpackChunkName: "crefo_checklist_create" */ "./views/crefo/ChecklistCreate.vue")
            },
            {
                path: "checklist/create/:id",
                name: "crefo_checklist_createlist",
                component: () => import(/* webpackChunkName: "crefo_checklist_create" */ "./views/crefo/ChecklistCreate.vue")
            },
            {
                path: "checklist/edit/:id",
                name: "crefo_checklist_edit",
                component: () => import(/* webpackChunkName: "crefo_checklist_edit" */ "./views/crefo/Checklist.vue")
            },
            {
                path: "configs",
                name: "crefo_configs",
                component: () => import(/* webpackChunkName: "crefo_configs" */ "./views/crefo/CheckConfigs.vue")
            },
            {
                path: "config/create",
                name: "crefo_config_create",
                component: () => import(/* webpackChunkName: "crefo_config" */ "./views/crefo/CheckConfig.vue")
            },
            {
                path: "config/edit/:id",
                name: "crefo_config_edit",
                component: () => import(/* webpackChunkName: "crefo_config" */ "./views/crefo/CheckConfig.vue")
            },
            {
                path: "assignments",
                name: "crefo_assignments",
                component: () => import(/* webpackChunkName: "crefo_assignments" */ "./views/crefo/Assignments.vue")
            },
            {
                path: "assignment/edit/:id",
                name: "crefo_assignment_edit",
                component: () => import(/* webpackChunkName: "crefo_assignment_edit" */ "./views/crefo/Assignment.vue")
            }
        ]
    },
    {
        path: "/ktvm",
        name: "",
        component: Wrapper,
        children: [
            {
                path: "unternehmen_blacklist",
                name: "ktvm_unternehmen_blacklist",
                component: () => import(/* webpackChunkName: "ktvm_unternehmen_blacklist" */ "./views/ktvm/BlacklistUnternehmen.vue")
            },
            {
                path: "mapping_ms",
                name: "ktvm_mapping_ms",
                component: () => import(/* webpackChunkName: "ktvm_mapping_ms" */ "./views/ktvm/MappingMS.vue")
            }
        ]
    },
    {
        path: "/bsv",
        name: "",
        component: Wrapper,
        children: [
            {
                path: "antragsdaten_pruefen",
                name: "bsv_antragsdaten_pruefen",
                component: () => import(/* webpackChunkName: "ktvm_unternehmen_blacklist" */ "./views/bsv/AntragsdatenPruefen.vue")
            }
        ]
    },
    {
        path: "/statistics",
        name: "statistics",
        component: () => import(/* webpackChunkName: "statistics_recipients" */ "./views/StatisticsRecipients.vue")
    },
    {
        path: "/docs",
        name: "",
        component: Wrapper,
        children: [
            {
                path: "",
                name: "docs_process_list",
                component: () => import(/* webpackChunkName: "docs_process_list" */ "./views/docs/ProcessList.vue")
            },
            {
                path: "process/:id",
                name: "docs_process_edit",
                component: () => import(/* webpackChunkName: "docs_process_edit" */ "./views/docs/Process.vue")
            },
            {
                path: "pdfforms",
                name: "docs_pdfforms",
                component: () => import(/* webpackChunkName: "docs_pdfforms" */ "./views/docs/PdfForms.vue")
            },
            {
                path: "pdfform/create/:product",
                name: "docs_pdfform_create",
                component: () => import(/* webpackChunkName: "docs_pdfform_create" */ "./views/docs/PdfFormCreate.vue")
            },
            {
                path: "pdfform/edit/:product/:id",
                name: "docs_pdfform_edit",
                component: () => import(/* webpackChunkName: "docs_pdfform_edit" */ "./views/docs/PdfFormEdit.vue")
            },
            {
                path: "assignments",
                name: "docs_assignments",
                component: () => import(/* webpackChunkName: "docs_assignments" */ "./views/docs/Assignments.vue")
            }
        ]
    }
]);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: allRoutes
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const authRequired = !to.meta.public;
    const loggedIn = VueCookie.get("data_token") !== null;

    if (authRequired && !loggedIn) {
        window.location.href = "/v1/oauth/login";
        return;
    }

    next();
});

export default router;
